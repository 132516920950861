import React from 'react';
import { Link } from 'react-router-dom';
import QuarterlyExport from './QuarterlyExport';

function Subject({ children, margin }) {
	return <p className={`mb-0 border-bottom fw-bold${margin?' mt-3':''}`}>{children}</p>;
}

function Option({ children, br=false, ...rest }) {
	return <><Link {...rest} className="menu-option rounded-1 mt-1 ps-2 w-100">{children}</Link>{br && <br/>}</>;
}

export function MenuOptions({ user, country }) {
	const admin = user.roles.includes('admin');
	const approver = user.roles.includes('approver');
	return <div className="p-3">
		<Subject>Process Transactions</Subject>
		<Option to="/transactions/new" br>New Transactions</Option>
		<Option to="/purchaseentries" br>Purchase Entries</Option>
		<Option to="/purchaseentries/reports?state=new">Transaction Reports</Option>
		<Option to="/transactions/msp">MultiSafepay TX</Option>

		<Subject margin>Device Management</Subject>
		<Option to="/devices" br>Devices</Option>
		<Option to="/devices/links" br>Linked Devices</Option>
		<Option to="/deviceTypes" br>PIN Device Types</Option>
		<Option to="/partnerCodes">Partner Codes</Option>
		<Option to="/cardTypes">Payment card types</Option>
		<Option to="/commissions">Commission packages</Option>

		<Subject margin>Invoice Transactions</Subject>
		<Option to="/invoices/new" br>Invoices</Option>
		<Option to="/invoices/create">Create Invoices</Option>

		<Subject margin>Reports</Subject>
		<Option to="/transactions/filterexport" br>Transaction CSV Export</Option>
		{country === 'BE' && <Option to="/transactions/quantityimport" br>Transaction CSV Import</Option>}
		<Option to="/transactions/partners" br>Transaction by Partner</Option>
		<Option to="/transactions/customerstats" br>Customer Stats</Option>
		<Option to="/devices/availability">Device Availability</Option>

		<Subject margin>Administrative</Subject>
		<Option to="/configure" br>Configure</Option>
		{admin && <Option to="/users" br>Users</Option>}
		<Option to="/oauth/exact" br>Connect Exact</Option>
		<Option to="/transactions" br>ABN Transactions</Option>
		{approver && <Option to="/transactions/archive" br>Archive Transactions</Option>}
		<Option to="/calls">Calls To Exact</Option>
	</div>;
}

export default function Menu({ user, country }) {
	return <div className="col-lg-2 d-none d-sm-block p-0 mb-4">
		<div className="rounded-1 border border-2 bg-white mb-4 shadow-sm">
			<h4 className="border-bottom p-3 m-0">Menu</h4>
			<MenuOptions user={user} country={country} />
		</div>
		<QuarterlyExport />
	</div>;
}
